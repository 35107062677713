.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navbutton {
  transition: all 0.1s linear;
  border: transparent;
  padding: 13px 26px;
  background-color: black;
  color: orange;
  margin-right: 5px;
  font-size: 15px;

  @media only screen and (max-width: 600px) {
    padding: 10px 14px;
  }
}

.navbutton:hover {
  color: red;
}
